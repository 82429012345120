//Smooth Scroll
function scrollToIdOnClick(event) {
  event.preventDefault();

  if (window.matchMedia("(min-width:600px)").matches) {
    var to = getScrollTopByHref(event.target) - 75;
  } else {
    var to = getScrollTopByHref(event.target) - 50;
  }

  scrollToPosition(to);
}

function scrollToPosition(to) {
  smoothScrollTo(0, to, 1500);
}

function getScrollTopByHref(element) {
  const id = element.getAttribute("href");
  return document.querySelector(id).offsetTop;
}

function smoothScrollTo(endX, endY, duration) {
  const startX = window.scrollX || window.pageXOffset;
  const startY = window.scrollY || window.pageYOffset;
  const distanceX = endX - startX;
  const distanceY = endY - startY;
  const startTime = new Date().getTime();

  duration = typeof duration !== "undefined" ? duration : 400;

  // Easing function
  const easeInOutQuart = (time, from, distance, duration) => {
    if ((time /= duration / 2) < 1)
      return (distance / 2) * time * time * time * time + from;
    return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
  };

  const timer = setInterval(() => {
    const time = new Date().getTime() - startTime;
    const newX = easeInOutQuart(time, startX, distanceX, duration);
    const newY = easeInOutQuart(time, startY, distanceY, duration);
    if (time >= duration) {
      clearInterval(timer);
    }
    window.scroll(newX, newY);
  }, 1000 / 60); // 60 fps
}

//Modal

function loadModal(videoUrl, iframeEl) {
  const iframeAttributes = iframeEl.getAttributeNames();
  iframeAttributes.forEach(attrName => {
    if (attrName.match('data-gtm-yt-inspected')) {
      iframeEl.removeAttribute(attrName);
    };
  });
  iframeEl.src = videoUrl;
}

function closeModal() {
  const modal = document.querySelector('.modal-backdrop');
  modal.classList.add('dn');
}

function exibeCardsAssistencia() {
  $(".card-assistencia-tecnica").addClass("hidden");

  const estado = $("#slcEstadoAssistencia").val();
  const tipo = $("#slcTipoProduto").val();
  let arrTipo = [];

  if (tipo) {
    arrTipo = tipo.split(",");
  }

  if (estado && tipo) {
    let cle = "." + estado;
    let clt = "." + tipo;
    if (arrTipo.length == 1) {
      $(cle + clt).removeClass("hidden");
    }

    if (arrTipo.length == 2) {
      let clt1 = "." + arrTipo[0];
      let clt2 = "." + arrTipo[1];
      $(cle + clt1).removeClass("hidden");
      $(cle + clt2).removeClass("hidden");
    }
  }
}
