//Menu Mobile
const navMenuAction = document.querySelectorAll('#btn-menu, .main-nav-list a');
const menuMobile = document.getElementById("main-nav");

navMenuAction.forEach(item => {
    item.addEventListener('click', function(){
    menuMobile.classList.toggle('openmenu');
    });
});


