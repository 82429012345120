function init() {
  //Menu Smooth Scroll
  const anchors = document.querySelectorAll(".main-nav-list a");

  anchors.forEach((item) => {
    const href = item.getAttribute("href");
    
    if (href.includes("#") && !href.includes("./")) {
      item.addEventListener("click", scrollToIdOnClick);
    }
  });

  //Openned Modal
  const video = document.querySelectorAll(".play-iframe, .thumb-video");
  const modal = document.getElementById("modal");
  const iframeModal = document.getElementById("iframe-modal");

  video.forEach((item) => {
    item.addEventListener("click", function () {
      modal.classList.toggle("show");
      loadModal(item.getAttribute("data-src"), iframeModal);
    });
  });


  //Close Modal
  const closeModal = document.querySelectorAll(".fechar, .modal");
  const iframe = document.querySelectorAll("iframe");

  closeModal.forEach((item) => {
    item.addEventListener("click", function () {
      modal.classList.remove("show");
      iframe.forEach(i => {
        i.src = '';
      })
    });
  });
}

// window load binds
window.onload = init;

function DOMLoaded() {
  // these are not always necessary but sometimes they fuck with ya
  if (helpers.iOS) {
    document.querySelector("html").classList.add("ios");
  } else if (helpers.IE()) {
    document.querySelector("html").classList.add("ie");
  }

  $("#slcEstadoAssistencia").on('change', () => {
    exibeCardsAssistencia();        
  });
  
  $("#slcTipoProduto").on('change', () => {
    exibeCardsAssistencia();
  });
}

// domcontent binds
document.addEventListener("DOMContentLoaded", DOMLoaded);
